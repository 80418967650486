<template>
  <div>
    <img
        class="image"
        :src="`${api}/paste/${this.code}/raw`"
        :style="{ 'width': maxWidth + 'px' }"
        alt="image"
        @click="openRaw"/>
  </div>
</template>

<script>
import { URL_API } from '@/lib/constants'

export default {
  name: 'ImageDetail',
  props: {
    code: String,
    paste: Object,
    maxWidth: Number,
  },
  data: () => ({
    image: null,
    src: null,
  }),
  methods: {
    openRaw() {
      window.open(`${URL_API}/paste/${this.code}/raw`, "_self")
    }
  },
  computed: {
    api() {
      return URL_API
    },
  },
}
</script>

<style scoped>
.image:hover {
  cursor: pointer;
}
</style>